export const STATS = {
    // Home page
    all: {
        anchors: 126,
        borrowers: 17491,
        invoices: 291576,
        disbursed: 21603077000,
        aum: 2872056180,
    },
    // SaralPay Later
    bnpl: {
        anchors: 20,
        borrowers: 4577,
        invoices: 26433,
        disbursed: 7833811000,
        aum: 410459000,
    },
    // Vendor Finance
    vendor: {
        anchors: 7,
        borrowers: 105,
        invoices: 774,
        disbursed: 5176263000,
        aum: 959827978,
    },
    // Supplychain finance
    scf: {
        anchors: 17,
        borrowers: 125,
        invoices: 800,
        disbursed: 8593022000,
        aum: 1501769000,
    },
};
